import React, { useEffect } from 'react';

import EmailIcon from '../components/widgets/EmailIcon';
import { IntroLayout } from 'components';
import { createQueriesHook as createQueries } from '../utilities/allQueries';
import { navigate } from 'gatsby';
import { useTracking } from '../utilities/hooks';

const LicensePage = () => {
  const signupDetails = createQueries();

  useTracking('ConfirmAccount');

  const startOver = () => {
    if (signupDetails.unableToSendConfirmation) navigate('/');
  };

  useEffect(startOver);

  return (
    <IntroLayout text centered className="confirm-account">
      <EmailIcon />
      <h1 className="hero-text">Confirm your Account</h1>
      <h2>
        Thanks for signing up for GrowFlow. Your account is almost ready. To confirm your
        account go check your email:
      </h2>
      <h3>{signupDetails.email}</h3>
    </IntroLayout>
  );
};

export default LicensePage;
